import React, { useRef} from 'react';
import '../styles/variables.css';
import '../styles/Splash.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faWallet} from '@fortawesome/free-solid-svg-icons'

// import HookLiason from "../library/HookLiason"

import NJSplash from '../assets/img/NJsplash.webp'
import NJSplashWeb from '../assets/img/NJsplashWeb.webp'
import NJMasthead_ from '../assets/svg/NJMasthead.svg'
// import {motion } from 'framer-motion/dist/framer-motion'
import { motion } from "framer-motion"
import { Parallax } from 'react-parallax';


import 'react-awesome-button/dist/themes/theme-red.css';
import "../styles/Button.css"

// import {scroller} from 'react-scroll';
import { AwesomeButton } from "react-awesome-button";
import InkVaultLogo from '../assets/svg/InkVaultTextLogo.svg'


function Splash() {

    //  /* eslint-disable no-unused-vars */
    //  const [{
    //     accounts,
    //     name,
    //     Issue,
    //     maxPerWallet,
    //     totalSupply,
    //     maxSupply,
    //     mintPrice,
    //     errorMessage,
    //     currentWalletOverMax,
    //     currentWalletBalance,
    //     mintReady,
    //     verifiableMintReady,
    //     mintLocked,
    //     currentWalletTokenURIs,
    //     awaitingMintResponse,
    //     receivedMintResponse,
    //     ownerIsMe,
    //     pausedStatus,
    //     currentVerifiableWalletTokenIds
    // },
    // getName,
    // getIssueStruct,
    // getMaxPerWallet,
    // getTotalSupplyOfTokensMinted,
    // getMaxSupplyOfTokens,
    // getMintPrice,
    // isCurrentWalletOverMaxPerWallet,
    // checkIfMintLocked,
    // getAllTokenURIsInUsersWallet,
    // connectWallet,
    // getCurrentWalletConnected,
    // checkIfCurrentWalletIsContractOwner,
    // checkIfMintAvailable,
    // checkIfVerifiableMintAvailable,
    // checkIfMintIsPaused,
    // handleMint,
    // handleSignedMint,
    // handleVerifiedMint,
    // doMainWalletConnectFunctions,
    // getWhitelistMaxPerWallet,
    // withdrawal,
    // setBaseURI,
    // setBaseExtension,
    // getTokenUri,
    // toggleMintIsEnabled,
    // setMintLockPermanently,
    // pauseMint,
    // unpauseMint,
    // setWhitelistedMaxPerWallet,
    // handleMintTokensForMultipleAddresses,
    // setProvenanceHash,
    // addMerkleRoot,
    // getAllVerifiableTokensIDsInUsersWallet] = HookLiason(null);
    //     /* eslint-enable no-unused-vars */

    //     useEffect(() => {
    //         (async () => {
               
    //             await getCurrentWalletConnected()
    
    //         })()
    //       }, [])

    const ref = useRef()
    // const { scrollY } = useViewportScroll(ref)
    // const y = useTransform(scrollY, [0, 200], [0, -200])

    // const ConnectWalletBTN = () => (
            
       
    //     <AwesomeButton                        
    //       size="xlarge"
    //       type="primary"
    //       ripple
    //       onPress={() => connectWallet()}>
    //       <FontAwesomeIcon icon={faWallet} size="2x" /><span>&nbsp;</span>CONNECT WALLET</AwesomeButton> 
         
    //       )

          

    const SplashBGContainer = () => (
        //
        //  
      

        <Parallax className="splash-parallax-container"
        bgImage={window.innerWidth <= 990 ? NJSplashWeb: NJSplash}
        bgImageStyle={{display:"flex", height: "100%", "objectFit": "cover", overflow: "visible"}} 
        //bgClassName='bgSplashImageClass'
        strength={200}
        renderLayer={percentage => (
            <div 
                style={{
                   
                    top: '0',
                    position: 'absolute',
                    background: `linear-gradient(to bottom right, rgba(0, 0, 0, ${percentage * .1}), rgba(0, 0, 0, ${percentage * .8}), black)`,
                    width: '100%',
                    height: '100%'
                }}
            />
        )}
         > 

     
            <motion.div className="splash-grid-container" 
                // initial={{opacity:1, y:0}}
                // style={{
                //     y: y
                //   }}
            >
                <div className="splash-main-content-high"  
                >
                    
                    <motion.div
                        initial={{opacity:0, y:-200}}
                        animate={{opacity:1, y:0}}
                       
                        transition={{duration:.4, delay:1}}

                    >
                        
                        <img className="splash-center-horiz" src={NJMasthead_} width="50%" alt="Night Jackal"/>
                 
                    </motion.div>
                  

                </div>

               <div className="splash-main-content-low splash-center-horiz" >
                <motion.div className="textblock center"
                    initial={{opacity:0, y:-200}}
                    animate={{opacity:1, y:0}}
                
                    transition={{duration:.4, delay:1.3}}
                >
                {/*
                
                MARKETING TAGS that will also need noodling

                You’ve waited long enough for a true hero’s journey.
                You owe it to yourself to dive into this adventure.
                You've found your next great saga.
                You're worthy of this epic story.
                You don't want to miss out on this journey.
                You're ready for the next big thing in comics.

                */}
                <span className="xl-bold"> You've Found Your Next Great Saga</span><br />
                {/* <span className="em">NEXT-GEN COMIC BOOK DISTRIBUTION</span><br/> */}
                <span>Includes rare variants! Limited supply for each book so act fast!</span><br/>
                {/* <span>Only on InkVault</span> */}
                
                <div className="splash-button-cont">
                
                       

                     
                        <AwesomeButton 
                    
                    className=""
                    size="xlarge"
                    type="primary"
                    ripple

                    onPress={() => {
                            const newWindow = window.open('https://inkvault.io', '_blank', 'noopener,noreferrer');
                            if (newWindow) newWindow.opener = null;
                        }}
                    
                    >BUY NOW</AwesomeButton> <br/>
                    <div className='basic-margin'>
                    <span className='xs'>Only on </span><img src={InkVaultLogo} alt="InkVault Logo" width="12%" height="12%"/>
                    </div>
                
                </div>
                
                
              </motion.div>

               
              
             
            </div>
           
            </motion.div>
            
        </Parallax>


    )

    return (

        <div className="splash-body" ref={ref}>
            
            <motion.div className="splash-container"
            initial={{opacity:0}}
            animate={{opacity:1}}
            transition={{duration:1, delay:1}}
            >
                
                <SplashBGContainer />
                
                
            </motion.div>
            
           
        </div>

    )
}

export default Splash
