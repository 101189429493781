import React, {useState} from 'react'
import '../styles/variables.css';
import '../styles/NavBar.css'
import {Navbar, Nav, Container} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons'
import {faXTwitter} from '@fortawesome/free-brands-svg-icons'
import NavLogo from './svg/NavLogo'
// import {motion} from 'framer-motion/dist/es/index'
// import {motion} from 'framer-motion/dist/framer-motion'
import { motion } from "framer-motion"
// import HookLiason from "../library/HookLiason"
// import NightJackal_Corner_Box_Abi from "../abi/NightJackal_Corner_Box_Abi.json"

import {scroller} from 'react-scroll';


function NavBar() {

    // const primary_bc = {contract: process.env.REACT_APP_NIGHTJACKAL_CORNER_BOX_ADDRESS, abi: NightJackal_Corner_Box_Abi.abi}
   
    // /* eslint-disable no-unused-vars */
    // const [{
    //     accounts,
    //     name,
    //     Issue,
    //     maxPerWallet,
    //     totalSupply,
    //     maxSupply,
    //     mintPrice,
    //     errorMessage,
    //     currentWalletOverMax,
    //     currentWalletBalance,
    //     mintReady,
    //     verifiableMintReady,
    //     mintLocked,
    //     currentWalletTokenURIs,
    //     awaitingMintResponse,
    //     receivedMintResponse,
    //     ownerIsMe,
    //     pausedStatus,
    //     currentVerifiableWalletTokenIds
    // },
    // getName,
    // getIssueStruct,
    // getMaxPerWallet,
    // getTotalSupplyOfTokensMinted,
    // getMaxSupplyOfTokens,
    // getMintPrice,
    // isCurrentWalletOverMaxPerWallet,
    // checkIfMintLocked,
    // getAllTokenURIsInUsersWallet,
    // connectWallet,
    // getCurrentWalletConnected,
    // checkIfCurrentWalletIsContractOwner,
    // checkIfMintAvailable,
    // checkIfVerifiableMintAvailable,
    // checkIfMintIsPaused,
    // handleMint,
    // handleSignedMint,
    // handleVerifiedMint,
    // doMainWalletConnectFunctions,
    // getWhitelistMaxPerWallet,
    // withdrawal,
    // setBaseURI,
    // setBaseExtension,
    // getTokenUri,
    // toggleMintIsEnabled,
    // setMintLockPermanently,
    // pauseMint,
    // unpauseMint,
    // setWhitelistedMaxPerWallet,
    // handleMintTokensForMultipleAddresses,
    // setProvenanceHash,
    // addMerkleRoot,
    // getAllVerifiableTokensIDsInUsersWallet] = HookLiason(primary_bc);
    //     /* eslint-enable no-unused-vars */

       

    const [expanded, setExpanded] = useState(false)
    // const Link = Scroll.Link;

    // useEffect(() => {
    //     (async () => {

    //         await getCurrentWalletConnected()

    //     })()
    //   }, [])
    // useEffect(() => {
    //     (async () => {
    //         if (accounts.length >= 1) {
    //         await doMainWalletConnectFunctions()
    //         await getAllTokenURIsInUsersWallet()
    //         }
    //     })()
    //   }, [accounts])
     

    function overrideToggle() {
        
        setExpanded(prevExpanded => !prevExpanded)
    }

    // function parseVerifiablePass(){
            
    //      return (<Nav.Item>
    //             <Nav.Link className="navbar-social-buttons"
    //             href="/calendar" target="_self"
    //             >
    //                 <span >
    //             <FontAwesomeIcon 
    //                 icon={faCalendar}/>
    //                 </span>
    //             </Nav.Link>
    //         </Nav.Item> )
          
 
    // }

   


    // function conformIPFSURI(uri){
    //     if(uri.split("ipfs://").length > 1){
    //       //ipfs
    //       return uri.replace("ipfs://", "https://ipfs.io/ipfs/");
    //     }else{
    //       return uri;
    //     }
    //   }
    // function GetImages() {
    //     if(currentWalletTokenURIs.length > 0){
    //     return currentWalletTokenURIs
    //     .filter(e => !e.address)
    //     .map(function(item, i){
    //         return (
    //             <>
    //             <div key={i} style={{margin: "1% 0% 1% 2%"}}>
    //                     <AsyncImage src={item}/>
    //             </div>
           
    //             </>
    //         )
    //     })
    // }
    //}

    // const AsyncImage = (props) => {
    //     const [loadedSrc, setLoadedSrc] = React.useState("");
    //     const [loadedID, setLoadedID] = React.useState(9999999999);
    //     useEffect(() => {
    //         (async () => {
    //             if (props.src) {
    //                 try {
    //                     let response = await fetch(conformIPFSURI(props.src.uri));
    //                     let responseJson = await response.json();
    //                     setLoadedSrc(loadedSrc => conformIPFSURI(responseJson["image"]));
    //                     setLoadedID(loadedID => setLoadedID(props.src.id));
    //                   } catch (error) {
    //                     console.error(error);
    //                   }
    //             }
    //         })()
    //       }, [props.src])
    //     if (loadedSrc !== "" && loadedID !== 9999999999) {
    //         return (
    //             <>
    //             <div className="nav-img">
    //                 <a href={`https://opensea.io/assets/ethereum/${process.env.REACT_APP_NIGHTJACKAL_CORNER_BOX_ADDRESS}/${loadedID}`} target="_blank" rel="noopener noreferrer"><img src={loadedSrc} alt="cornerbox" style={{maxHeight: "100px"}}/></a>
    //             </div>
    //             </>
    //         );
    //     }
    //     return null;
    // };
 
    //todo: move logo to it's own component

    return (

        <>
       
        <motion.div
          initial={{y: -100}}
          animate={{y:0}}
          transition={{ y: { type: "spring", stiffness: 100}, type: "easeOut"}}
        >
           
           <Navbar 
           className="navbar"
           collapseOnSelect 
           //bg="dark" 
           variant="dark" 
           expand="lg"
           
           // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
           expanded={expanded} // only need to add this when configuring the nav to detect its expanded state
           onToggle={overrideToggle}
           >

            <Container className="navbar-container">

            

                <Navbar.Brand className="navbar-logo" href="/">
                        <motion.div
                         whileHover={{ scale: 1.1 }}
                         whileTap={{ scale: 0.9 }}
                        >
                            <NavLogo width="70px" height="70px"/>
                            
                        </motion.div>
                       

                </Navbar.Brand>

                
                
                <Navbar.Toggle className="navbar-toggle" aria-controls="basic-navbar-nav">
                <div>
                    <FontAwesomeIcon 
                        icon={expanded ? faTimes : faBars} color="#FFF" size="2x" 
                    />
                </div>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav px-auto">
                    <Nav className="navbar-menu ml-auto px-5"> 
                   
                                {/* <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                    onClick={() => scroller.scrollTo('nfts', {
                                        smooth: 'easeInOutQuint',
                                        offset: -20,
                                        duration: 500,
                                    })}
                                >NFTs</Nav.Link>
                                </Nav.Item> */}
                                
                                {/* <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                     onClick={() => scroller.scrollTo('roadmap', {
                                        smooth: 'easeInOutQuint',
                                        offset: -170,
                                        duration: 500,
                                    })}
                                >Roadmap</Nav.Link>
                                </Nav.Item> */}

                                <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                    onClick={() => scroller.scrollTo('lore', {
                                        smooth: 'easeInOutQuint',
                                        offset: 0,
                                        duration: 500,
                                    })}
                                >Lore</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                    onClick={() => scroller.scrollTo('video', {
                                        smooth: 'easeInOutQuint',
                                        offset: 0,
                                        duration: 500,
                                    })}
                                >Motion Comic</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                     onClick={() => scroller.scrollTo('team', {
                                        smooth: 'easeInOutQuint',
                                        offset: -70,
                                        duration: 500,
                                    })}
                                >Team</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link className="navbar-buttons"
                                        href="https://x.com/NightJackalGang" target="_blank"
                                    >
                                        Buy Now
                                    </Nav.Link>
                                </Nav.Item>
                                
                                {/* <Nav.Item>
                                <Nav.Link className="navbar-buttons" 
                                     onClick={() => scroller.scrollTo('faq', {
                                        smooth: 'easeInOutQuint',
                                        offset: -70,
                                        duration: 500,
                                    })}
                                >FAQ</Nav.Link>
                                </Nav.Item> */}
                    </Nav>
                     <Nav className="navbar-social">
                                <Nav.Item>
                                    <Nav.Link className="navbar-social-buttons"
                                        href="https://x.com/NightJackalGang" target="_blank"
                                    >
                                    <FontAwesomeIcon 
                                        icon={faXTwitter} className="twitter" />
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link className="navbar-social-buttons"
                                    href="https://discord.gg/N5f2XyBgtu" target="_blank"
                                    >
                                        <span >
                                    <FontAwesomeIcon 
                                        icon={faDiscord}/>
                                        </span>
                                    </Nav.Link>
                                </Nav.Item> */}
                              
                                {/* <>
                                    {currentWalletTokenURIs.length > 0?
                                        parseVerifiablePass()
                                    :
                                    <> </>}
                                </> */}
                                     
                    </Nav>
                       
                   
         
                </Navbar.Collapse>

                
                
            </Container>

            
           
            </Navbar> 
       
            
        </motion.div>
        
        {/* {currentWalletTokenURIs.length > 0 &&
        <>
        
        <div className='gradient-box-small' >
        <p className='softgray xxs' style={{margin: "1% 0 0 2%"}}>Your Corner Boxes...</p>
        <div style={{display: "flex"}}>
            {GetImages()}
        </div>
        </div>
        </>
        } */}

        </>
       
    )
}

export default NavBar
